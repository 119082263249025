import React, { useEffect } from 'react';

import { withCart } from '../Cart/context';
import { remove, update } from '../Cart/storage';

import { DeleteIcon } from '../Cart/icons';
import { TextColour } from '../../utils/variables';
import { DeletePlaceholder } from './placeholders';

import './items.css';

import loadComponent from '../Loadable';

const ButtonHidden = loadComponent('button-hidden');

export default withCart(function BasketItems({ items, updateTotal }) {
  useEffect(() => {
    function initItems() {
      updateTotal();
    }
    initItems();
  }, []);

  const updateProduct = (id, quantity) => {
    update(id, 'quantity', quantity);
    updateTotal();
  };

  const removeProduct = id => {
    remove(id);
    updateTotal();
  };

  const itemName = val => {
    return val.charAt(0).toUpperCase() + val.slice(1);
  };

  return (
    <div style={outerStyle}>
      {items.map((item, i) => {
        const cost = item.price * item.quantity;
        return (
          <div
            key={item.id}
            className="single"
            style={{
              marginTop: i !== 0 && `1.875rem`,
              paddingTop: i !== 0 && `1.875rem`,
              borderTop: i !== 0 && `1px dotted rgba(0,0,0,0.2)`,
            }}
          >
            <div className="details">
              <h6>{itemName(item.product)}</h6>
              {item.reference && (
                <span style={{ fontSize: 14, color: TextColour }}>
                  Reference: {item.reference}
                </span>
              )}
              <span style={{ fontSize: 14, color: TextColour }}>
                H:{item.height}mm x W:{item.width}mm
              </span>
            </div>
            <div className="price">
              <p>
                &pound;
                {parseFloat(Math.round(item.price * 100) / 100).toFixed(2)}
              </p>
            </div>
            <div className="quantity">
              <label htmlFor="qty" style={{ lineHeight: 1 }}>
                <strong
                  style={{
                    display: `block`,
                    fontSize: `10px`,
                    textAlign: `center`,
                    margin: `0 auto 5px`,
                  }}
                >
                  QTY
                </strong>
                <input
                  aria-label="Quantity"
                  id="qty"
                  type="number"
                  defaultValue={item.quantity}
                  min="1"
                  onChange={e =>
                    e.target.value > 0 && updateProduct(item.id, e.target.value)
                  }
                />
              </label>
            </div>
            <div className="subtotal">
              <p>
                &pound;{parseFloat(Math.round(cost * 100) / 100).toFixed(2)}
              </p>
            </div>
            <div className="delete">
              <ButtonHidden
                onClick={() => removeProduct(item.id)}
                fallback={<DeletePlaceholder />}
              >
                <DeleteIcon />
              </ButtonHidden>
            </div>
          </div>
        );
      })}
    </div>
  );
});

const outerStyle = {
  display: `flex`,
  flexDirection: `column`,
};
